import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private afs: AngularFirestore
  ) { }

  create(data): Promise<any> {
    data.date = firebase.firestore.FieldValue.serverTimestamp();
    return new Promise((resolve) => {
      this.afs.collection('contact').add(data).then(() => {
        resolve();
      });
    });
  }

}
