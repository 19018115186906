import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class I18nService {
  /**
   * ReplaySubject para manejar el idioma en una variable
   * @type {ReplaySubject<string>}
   */
  private subjectLanguage = new ReplaySubject<string>(1);

  /**
   * Constructor que inicia la carga del idioma
   * @param {TranslateService} translate
   */
  constructor(public translate: TranslateService) {
    this.getLanguageLocalStorage();
  }

  /**
   * Obteniene un observable del idioma actual
   * @returns {Observable<User>}
   */
  public get language() {
    return this.subjectLanguage.asObservable();
  }

  /**
   * Representa el idioma activo en el servicio.
   */
  public activeLanguage: string;


  /**
   * Obtiene el idioma guardado anteriormente
   */
  private getLanguageLocalStorage() {
    const lang = localStorage.getItem('language');
    if (lang != null) {
      this.translate.setDefaultLang('es');
      this.changeLanguage(lang);
      //this.subjectLanguage.next(lang);
      //this.changeLanguage(lang);
    } else {
      this.translate.setDefaultLang('es');
      localStorage.setItem('language', 'es');
      this.changeLanguage('es');

    }
  }

  /**
   * Cambia el idioma de la app
   * @param lang
   */
  public changeLanguage(lang) {
    localStorage.setItem('language', lang);
    this.translate.use(lang);
    this.subjectLanguage.next(lang);
    this.activeLanguage = lang;
  }
}
