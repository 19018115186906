import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'language', pure: false
})
export class LanguagePipe implements PipeTransform {

  constructor(
    private translate: I18nService,
  ) {
  }

  transform(value: any, args: any[] = null): any {
    if (typeof value === 'string') {
      return value;
    } else {
      return value[this.translate.activeLanguage] || value['es'] || value['en'];
    }
  }

}
