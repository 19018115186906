import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Proyect } from '../models/proyect';

@Injectable({
  providedIn: 'root'
})
export class ProyectService {

  url = "Proyects"

  constructor(
    private afs: AngularFirestore
  ) { }

  getAll(): Observable<Proyect[]> {
    return this.afs.collection(this.url, ref => ref.where('display', '==', true)).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }
}
