import { Component, OnInit, HostListener } from '@angular/core';
import { I18nService } from '../services/i18n.service';
import { ProyectService } from '../services/proyect.service';
import { Observable } from 'rxjs';
import { Proyect } from '../models/proyect';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public innerHeight: any;
  public scroll: any;
  selectedLang;
  proyects: Observable<Proyect[]>;
  contactForm: FormGroup;
  msgSent: boolean;
  menu: boolean;
  skillsInView: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  @HostListener('window:scroll', ['$event'])
  onscroll(event) {
    this.scroll = window.scrollY;
    const el = document.getElementById('skills');
    this.skillsInView = this.anyPartInView(el);
  }

  anyPartInView(el) {
    const rect = el.getBoundingClientRect();
    // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
  }

  constructor(
    private translate: I18nService,
    private proyectService: ProyectService,
    private fb: FormBuilder,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    // const altura = $('#skills').offset().top;
    this.innerHeight = window.innerHeight;
    const lang = localStorage.getItem('language');
    if (lang) {
      this.selectedLang = lang;
    } else {
      this.selectedLang = 'es';
    }
    this.createForm();

    this.proyects = this.proyectService.getAll();
    this.proyects.subscribe(p => {
      // console.log('proyects: ', p);
    });
  }

  createForm() {
    this.contactForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      msg: ['', Validators.required],
      name: ['', Validators.required],
      captcha: ['', Validators.required]
    });
  }

  changeLanguage() {
    // console.log('changin lang:', this.selectedLang);
    this.translate.changeLanguage(this.selectedLang);
  }

  toggleMenu() {
    this.menu = !this.menu;
  }

  onSubmit() {
    this.msgSent = true;
    let data;
    data = this.contactForm.value;
    try {
      this.contactService.create(data).then(() => {
      });
    } catch (error) {
      console.log('Error ocurred', error);

    }
  }

}
